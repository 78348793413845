//test
import React, { useEffect } from "react";
import { isMobile, isTablet } from "react-device-detect";

const Hem = () => {

  return (
    <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh', 
        userSelect: 'none'
    }}>
      <h1>Välkommen till nya Skola 77</h1>



    </div>
    
  );
};

export default Hem;
