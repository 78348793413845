import React, { useState, useEffect, useRef } from "react";
import { data as originalData } from "./data";
import NamnRuta from "./Namn";
import { RiDeleteBin6Line } from "react-icons/ri";
import ExcelToTextConverter from "./ExcelToTextConverter";
import { isMobile, isTablet } from "react-device-detect";
import { compress } from "lz-string";
import { height } from "@fortawesome/free-solid-svg-icons/fa0";
function generateUniqueId() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function divideArray(lista, x) {
  if (x <= 0) throw new Error("Number of parts must be greater than 0.");
  const result = [];
  const list = lista.filter((namn) => namn !== "");
  const partSize = Math.floor(list.length / x);
  let remainder = list.length % x;
  let start = 0;

  for (let i = 0; i < x; i++) {
    const end = start + partSize + (remainder > 0 ? 1 : 0);
    result.push(list.slice(start, end));
    start = end;
    if (remainder > 0) remainder--;
  }

  return result;
}

const Klasser = ({}) => {
  const [namn, setNamn] = useState([""]);
  const textrutaRef = useRef(null);
  const [visaLaddaKlassrum, setVisaLaddaKlassrum] = useState(false);
  const [kolumner, setKolumner] = useState(10);
  const [klassnamn, setKlassnamn] = useState(null);
  const [klassId, setKlassId] = useState(null);
  const [klassnamntext, setKlassnamntext] = useState("ny klass");
  const [data, setData] = useState(null);
  const [textareaValue, setTextareaValue] = useState("");

  const defaultKlass = "ny klass";
  async function checkLoginStatus() {
    setData(originalData);
  }

  function sparaData(nyData) {
    fetch("https://auth.skola77.com/updateData", {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(nyData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.message);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  const filRef = useRef(null);

  useEffect(() => {
    checkLoginStatus();
  }, []);
  async function checkLoginStatus() {
    try {
        const response = await fetch("https://auth.skola77.com/home", {
            credentials: "include",
        });
        const result = await response.json();

        try {
            const parsedData = JSON.parse(result.data);
            setData(parsedData);
            const klassrum = parsedData.klassrum;
            const klasser = parsedData.klasser;

            console.log("Klassrum:", klassrum);
            console.log("Klasser:", klasser);
        } catch (parseError) {
            console.error("Kunde inte parsa data:", parseError);
            window.location.href = "https://auth.skola77.com?skola77";
        }
    } catch (fetchError) {
        console.error("Fel vid hämtning av data:", fetchError);
        window.location.href = "https://auth.skola77.com?skola77";
    }
}

  const handleTextareaChange = (e) => {
    setTextareaValue(e.target.value);
  };

  const läggTillNamn = () => {
    const textareaContent = textrutaRef.current.value
      .split("\n")
      .map((name) => name.trim())
      .filter(Boolean);

    setNamn((prevNames) => [...prevNames, ...textareaContent]);
    textrutaRef.current.value = "";
  };
  const updateName = (newName, index) => {
    setNamn((prevNamn) => {
      const updatedNamn = [...prevNamn];
      updatedNamn[index] = newName;
      return updatedNamn;
    });
  };
  const spara = (nyttNamn) => {
    let newData = data;
    let nyttKlassNamn = nyttNamn
      ? nyttNamn
      : klassId || klassnamntext !== defaultKlass
      ? klassnamntext
      : prompt("Vad heter klassen?");
    if (klassnamn !== klassnamntext) {
      while (newData.klasser.some((klass) => klass.namn === nyttKlassNamn)) {
        nyttKlassNamn = prompt(
          "Du har redan lagt in en klass som heter så. Skriv ett namn som skiljer sig åt."
        );
      }
    }
    if (!nyttKlassNamn) {
      return;
    }
    if (klassId) {
      newData.klasser = data.klasser.map((klass) => {
        if (klass.id === klassId) {
          return {
            id: klass.id,
            namn: nyttKlassNamn,
            personer: namn,
          };
        } else {
          return klass;
        }
      });
    } else {
      const nyttId = generateUniqueId();
      setKlassId(nyttId);
      newData.klasser.push({
        id: nyttId,
        namn: nyttKlassNamn,
        personer: namn,
      });
    }
    console.log(newData);

    sparaData(newData);

    setKlassnamn(nyttKlassNamn);
    setKlassnamntext(nyttKlassNamn);
  };

  const taBortEfternamn = () => {
    setNamn((förraNamn) => förraNamn.map((namn) => namn.split(" ")[0]));
  };
  const namnILista =
    namn &&
    divideArray(
      namn
        .map((namn, index) => ({ namn: namn, orginalIndex: index }))
        .sort((a, b) => a.namn.localeCompare(b.namn))
        .slice(1)
        .filter((namnObj1) => namnObj1.namn !== "")
        .map((namnObj) => (
          <div
            key={namnObj.orginalIndex}
            className="bg-white w-[200px]  h-[40px] m-1 border flex flex-row justify-start items-center"
          >
            <div className="text-[20px] ml-[1vw] overflow-visible w-[90%]">
              <NamnRuta
                namn={namnObj.namn}
                allaNamn={namn}
                setNamn={updateName}
                index={namnObj.orginalIndex}
              />
            </div>

            <div
              style={{ color: "white", cursor: "pointer" }}
              onClick={() => {
                setNamn((prevNamn) => {
                  const newNamn = prevNamn.map((namn, index) => {
                    return index === namnObj.orginalIndex ? "" : namn;
                  });
                  console.log(namnObj.orginalIndex);
                  return newNamn;
                });
              }}
              className="bg-red-600 aspect-square h-[100%] flex flex-row items-center justify-center text-white text-center"
            >
              <RiDeleteBin6Line />
            </div>
          </div>
        )),
      Math.floor(window.outerWidth / 220)
    );
  const taBortKlass = (id) => {
    let nyData = data;
    nyData.klasser = nyData.klasser.filter((klass) => klass.id !== id);
    setData(nyData);
    sparaData(nyData);
  };
  const namnLista =
    namn &&
    divideArray(
      namn
        .map((namn, index) => ({ namn: namn, orginalIndex: index }))
        .sort((a, b) => a.namn.localeCompare(b.namn))
        .slice(1)
        .map((namnObj) => (
          <div
            key={namnObj.orginalIndex}
            className="bg-white w-[200px] h-[40px] m-1 border flex flex-row justify-start items-center"
          >
            <div className="text-[20px] w-[90%]">
              <div>{namnObj.namn}</div>
            </div>

            <div
              style={{ color: "white", cursor: "pointer" }}
              onClick={() => {
                setNamn((prevNamn) => {
                  const newNamn = prevNamn.map(namn, (index) =>
                    index === namnObj.orginalIndex ? "" : namn
                  );
                  return newNamn;
                });
              }}
              className="bg-red-600 aspect-square h-[100%] flex flex-row items-center justify-center text-white text-center"
            >
              <RiDeleteBin6Line />
            </div>
          </div>
        )),
      kolumner
    );
  useEffect(() => {
    const uppdateraKolumner = () => {
      setKolumner(Math.floor(window.outerWidth / 216));
    };
    uppdateraKolumner();
    window.addEventListener("resize", uppdateraKolumner);
    return () => {
      window.removeEventListener("resize", uppdateraKolumner);
    };
  }, []);
  useEffect(() => {
    setKolumner(Math.floor(window.outerWidth / 216));
  }, [namn]);

  return (
    <div>
      <div
        className="fixed bottom-5 cursor-pointer rounded-lg aspect-square right-5 h-20 flex justify-center text-white items-center bg-red-500"
        onClick={() => {
          if (
            window.confirm(
              "Är du säker på att du vill radera klassen? Om inte, tryck på avbryt."
            )
          ) {
            let nyData = data;
            nyData.klasser = nyData.klasser.filter(
              (klass) => klass.id !== klassId
            );
            sparaData(nyData);
            setNamn([""]);
            setKlassId(null);
            setKlassnamn(null);
            setKlassnamntext("ny klass");
          }
        }}
      >
        <RiDeleteBin6Line style={{ width: "65%", height: "65%" }} />
      </div>
      <ExcelToTextConverter ref={filRef} names={namn} setNames={setNamn} />
      {visaLaddaKlassrum && (
        <div
          className="bg-[#4CAF50]"
          style={{
            position: "absolute",
            top: "calc(50vh - 122px)",
            left: "calc(50vw - 98.905px)",
            listStyle: "none",
          }}
        >
          <div className="bg-[#4CAF50] text-white font-semibold flex h-8 text-xl justify-center items-center">
            Sparade klasser:{" "}
          </div>
          <div className="border-8 border-t-0 border-[#4CAF50]">
            <div className="bg-white h-[236px] overflow-y-scroll scrollbar-none border-8 border-t-0 border-[#4CAF50]">
              <li
                key={"nyKlass"}
                className="font-bold hover:bg-slate-100 text-xl p-2 cursor-pointer"
                onClick={() => {
                  setNamn([""]);
                  setKlassId(null);
                  const nyttNamn = prompt("Vad ska din nya klass heta?");
                  if (!nyttNamn) {
                    return;
                  }
                  setVisaLaddaKlassrum(false);
                  spara(nyttNamn);
                }}
              >
                ny klass...
              </li>
              {data.klasser.map((klass) => {
                return (
                  <li
                    key={klass.id}
                    className="font-bold hover:bg-slate-100 text-xl p-2 cursor-pointer"
                    onClick={() => {
                      setNamn(klass.personer);
                      setKlassId(klass.id);
                      setKlassnamn(klass.namn);
                      setKlassnamntext(klass.namn);
                      setVisaLaddaKlassrum(false);
                    }}
                  >
                    {klass.namn}
                  </li>
                );
              })}
            </div>
          </div>
        </div>
      )}
      <div className="flex">
        <div>
          <div
            style={{
              height: window.outerWidth > window.outerHeight ? "7vw" : "13vw",
            }}
            className={`bg-[#4CAF50] border-b text-white w-[25vw] flex cursor-pointer flex-row text-[5vw] justify-center items-center`}
            onClick={() => spara()}
          >
            Spara
          </div>
          <div
            onClick={() => {
              setVisaLaddaKlassrum(!visaLaddaKlassrum);
            }}
            style={{
              height: window.outerWidth > window.outerHeight ? "7vw" : "13vw",
            }}
            className={`bg-[#4CAF50] border-t  text-white w-[25vw] flex cursor-pointer flex-row text-[5vw] justify-center items-center`}
          >
            Ladda
          </div>
        </div>
        <textarea
          ref={textrutaRef}
          style={{
            fontSize:
              window.outerWidth > window.outerHeight ? "1.8vw" : "2.5vw",
            height: window.outerWidth > window.outerHeight ? "14vw" : "26vw",
          }}
          className={`w-[50vw]`}
          placeholder={`Ett namn per rad:
Artur
Bosse
Sam 
etc...
`}
        ></textarea>
        <div
          className={`cursor-pointer border bg-[#4CAF50] text-white w-[25vw] h-[window.outerWidth > window.outerHeight ? "6.25" : "12.5vw"] flex flex-row justify-center items-center text-[5vw]`}
          onClick={läggTillNamn}
        >
          Lägg till
        </div>
      </div>
      <div className="grid grid-cols-3 w-full">
        <div
          className="text-center items-center border-[#af4cab] mr-0.5 rounded-none flex cursor-pointer justify-center text-white text-[2vw] rounded-[4px] font-semibold border bg-[#af4cab]"
          onClick={() => {
            filRef.current.click();
          }}
        >
          importera namn från kalkylark
        </div>
        <div
          className="text-center rounded-[4px] cursor-pointer items-center flex justify-center text-white text-[2vw] font-semibold rounded-none border-[#af4cab] bg-[#af4cab]"
          onClick={taBortEfternamn}
        >
          ta bort efternamn
        </div>

        <div className="text-4xl text-center m-3">
          {
            <input
              className="text-[4vw] h-[6vw] truncate w-[90%] bg-inherit text-center outline-none m-3"
              onChange={(e) => {
                setKlassnamntext(e.target.value);
              }}
              value={klassnamntext}
            />
          }
        </div>
      </div>

      <div className="text-xl ">{`Antal elever: ${
        namn
          .map((namn, index) => ({ namn: namn, orginalIndex: index }))
          .sort((a, b) => a.namn.localeCompare(b.namn))
          .slice(1)
          .filter((namnObj1) => namnObj1.namn !== "").length
      }`}</div>
      <div
        className="m-auto"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {namnILista.map((kolumn) => (
          <div>{kolumn}</div>
        ))}
      </div>

      <hr />
    </div>
  );
};

export default Klasser;
